import Cmp from './FrontPage.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query currentHomePageQuery($id: String!, $lang: String!) {
    contentfulHomepage(id: { eq: $id }, node_locale: {eq: $lang}) {
      id
      node_locale
      mainTitle
      recommendTitle
      numbersTitle
      numberBlocks {
        headingLabel
        number
      }
      videoTitle
      recommendedItems {
        title
        url
        image {
          gatsbyImageData(quality: 92, width: 360, height: 490, layout: FIXED, formats: [AUTO, WEBP])
        }
      }
      heroVideo {
        file {
          url
        }
      }
      youtubeLink
    }
  }
`

export default Cmp
